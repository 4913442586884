var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_mattertype'),expression:"'mesh.add_mattertype'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-matter-type-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames("mesh.mattertype", false, "Tipo De Asignatura"))+" ")]):_vm._e()])]),_c('h3',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.mattertype", true, "Tipos de Asignatura"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.mattertype',"allows_crud":_vm.allows_crud}})],1),(_vm.searchMatterType.length > 0)?_c('GenericBTable',{staticClass:"matter-type-table",attrs:{"items":_vm.matter_types,"pagination":_vm.matter_types.length,"fields":_vm.matterTypesFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames(
              'mesh.mattertype',
              false,
              'Tipo De Asignatura'
            )}`
          ),expression:"\n            `Editar ${$getVisibleNames(\n              'mesh.mattertype',\n              false,\n              'Tipo De Asignatura'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-matter-type-modal-${row.item.id}`)}}}),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Eliminar ${_vm.$getVisibleNames(
              'mesh.mattertype',
              false,
              'Tipo De Asignatura'
            )}`
          ),expression:"\n            `Eliminar ${$getVisibleNames(\n              'mesh.mattertype',\n              false,\n              'Tipo De Asignatura'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteMatterType(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-matter-type-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.mattertype',
            false,
            'Tipo De Asignatura'
          )}`,"size":"md","hide-footer":""}},[_c('MatterTypeForm',{attrs:{"MatterType":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedMatterType}})],1)]}}:null],null,true)}):_c('div',[_c('br'),_c('strong',[_vm._v("No se a encontrado ningún "+_vm._s(_vm.$getVisibleNames( "mesh.mattertype", false, "Tipo De Asignatura" ).toLowerCase())+".")])])],1),_c('b-modal',{attrs:{"id":`new-matter-type-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.mattertype',
      false,
      'Tipo De Asignatura'
    )}`,"size":"md"}},[_c('MatterTypeForm',{attrs:{"show_title":false,"institution_id":this.user.school},on:{"created":_vm.slotCreatedMatterType}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }